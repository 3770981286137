@import "../../variables";
.home-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  position: relative;
  top: 30vh;
  background-repeat: no-repeat, no-repeat;
  background-image: url(../../images/bg1.png), url(../../images/bg2.png);
  background-position: 0 0, 100% 100%;
  background-size: contain, 35%;
  > .home-container {
    max-width: $appmaxwidth;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    > h1 {
      color: #fcb900;
      padding: 0 20px;
      font-size: 45px;
      font-weight: 500;
      margin: 0;
    }
    > p {
      color: #fff;
      padding: 0 20px;
      font-weight: 200;
      font-size: 18px;
    }
    > button {
      background-color: #fcb900;
      border-radius: 50px;
      padding: 12px 45px;
      margin: 50px;
      outline: 0;
      border: none;
      cursor: pointer;
      > a {
        text-decoration: none;
        color: #fff;
      }
    }
    > img {
      width: 60%;
    }
  }
}
