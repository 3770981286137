@import "../../variables";
.footer-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  position: relative;
  top: 30vh;
  .footer-content {
    max-width: $appmaxwidth;
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    .top {
      width: 90%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 2rem;
      background-color: #fcb900;
      padding: 40px 80px;
      border-radius: 5px;
      h5 {
        font-size: 26px;
        margin: 20px 0;
      }
      > .button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        button {
          background: #fcb900;
          border-radius: 5px;

          padding: 12px 36px;
          font-size: 14px;
          border: 1px solid #fff;
          cursor: pointer;
          > a {
            text-decoration: none;
            color: #fff;
          }
        }
      }
    }
    .bottom {
      color: #fcb900;
      border-top: 1px solid #fcb900;
      width: 90%;
      margin: 50px;
      display: flex;
      justify-content: space-between;

      .operated {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
      img {
        margin: 20px;
        width: 40px;
      }
    }
  }
}
@media screen and (min-width: 768px) {
  .bottom {
    > div {
      width: 30%;
      text-align: center;
    }
  }
}
@media screen and (max-width: 768px) {
  .footer-wrapper {
    .top {
      margin: 20px;
      padding: 10px;
      display: flex;
      flex-direction: column;
      > .button-container {
        margin-top: 20px;
      }
    }
    .bottom {
      width: 100%;
      display: flex;
      flex-direction: column;

      > div {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
}
