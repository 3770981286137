@import "../../variables";
.navbar-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 500;
  height: 100px;
  color: #fff;
  > .navbar-container {
    width: 100%;
    max-width: $appmaxwidth;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 0 80px;
    > a {
      text-decoration: none;
      display: flex;
      > img {
        margin: 0;
        padding: 0;
        width: 250px;
      }
    }
    > div {
      display: flex;
      > a {
        font-size: 16px;
        color: #fff;
        padding: 0 25px;
        text-decoration: none;
        margin-top: 11px;
        font-weight: bold;
      }
      > a:hover {
        transform: scale(1.2);
      }
      > button {
        background-color: #171717;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 12px 28px;
        margin-left: 200px;
        border-radius: 50px;
        border: 1px solid #fcb900;
        > a {
          font-size: 14px;
          color: #fcb900;
          text-decoration: none;
          font-weight: bold;
        }
      }
    }
  }
}
.navbar-wrapper.active {
  display: none;
}

@media screen and (max-width: 991px) {
  .navbar-wrapper {
    display: none;
  }
}
