.planCard-wrapper {
  width: 300px;
  margin: 40px;
  background-color: #fff;
  padding: 20px;
  box-shadow: 0px 24px 50px rgba(54, 91, 125, 0.05);
  border-radius: 5px;
  > .title-container {
    justify-content: left;
    align-items: center;
    > h3 {
      text-align: left;
      color: #02073e;
      font-size: 26px;
      margin: 0;
    }
  }

  > div {
    color: #0f2137;
    text-align: center;
    width: 100%;
    > h1 {
      margin: 5px;
      font-size: 32px;
    }
  }
  > p {
    color: #0f2137;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 10px;
  }
  > div {
    > button {
      width: 50px;
      height: 40px;
      cursor: pointer;
      margin: 0;
      border: 1px solid #dce5ea;
      box-sizing: border-box;
      border-radius: 5px;
      background-color: #fff;
    }
    > .selected {
      background: #fff9e9;
      border: 1px solid #fcb900;
      color: #fcb900;
    }
  }
  > ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    list-style-type: none;
    padding: 0;
    margin: 0.5rem 1.5rem;
    font-size: 85%;
    height: 330px;
    > li {
      position: relative;
      padding: 0;
      color: #343d48;
      padding-bottom: 10px;
      font-weight: 500;
    }
    li:before {
      /*
      The desired width gets defined in two places: The element width, and background size.
      The height only gets defined once, in background size.
      */
      position: absolute;
      display: block;
      content: "\2022"; /* bullet point, for screen readers */
      text-indent: -999999px; /* move the bullet point out of sight */
      left: -20px;
      width: 20px; /* desired width of the image */
      height: 20px; /* unrelated to image height; this is so it gets snipped */
      background-repeat: no-repeat;
      background-image: url("../../../images/tick.png");
      background-size: 15px 15px;
      background-position: 0 0.3em;
    }
  }
  > a {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin: 0;
    > button {
      border: none;
      cursor: pointer;
      background-color: #fcb900;
      box-sizing: border-box;
      border-radius: 5px;
      color: #fff;
      padding: 12px;
      width: 100%;
      font-size: 14px;
    }
  }
}
@media screen and (max-width: 760px) {
  .planCard-wrapper {
    margin: 10px;
  }
}
