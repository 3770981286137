@import "../../variables";
.plans-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 50px 0;
  position: relative;
  top: 30vh;
  .plans-container {
    max-width: $appmaxwidth;
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    > p {
      color: #fff;
      text-align: center;
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: 2px;
    }
    > h5 {
      color: #fcb900;
      margin: 0;
      font-size: 20px;
      margin-bottom: 20px;
    }
    .tabs-container {
      background-color: #f7f8fb;
      padding: 5px;
      border-radius: 5px;
      margin: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      > button {
        text-align: center;
        font-size: 14px;
        padding: 10px 25px;
        color: #0f2137;
        border: none;
        outline: 0;
        background-color: #f7f8fb;
        cursor: pointer;
        font-weight: 600;
      }
      > .selected {
        border-radius: 5px;
        color: #0f2137;
        background-color: #ffeec0;
      }
    }
    > .content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    > p {
      font-size: 14px;
    }
  }
}
