@import "../../variables";
.features-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding: 50px 0;
  position: relative;
  top: 30vh;
  > .features-container {
    max-width: $appmaxwidth;
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > h5 {
      color: #fcb900;
      margin: 0;
      font-size: 20px;
      margin-bottom: 50px;
    }
    > p {
      color: #fff;
      text-align: center;
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: 2px;
    }
    > div {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
